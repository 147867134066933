<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the mathematical expressions shown below, determine the answer and enter your
        response with the appropriate number of significant figures.
      </p>

      <p class="mb-2">a) <stemble-latex :content="expression1" /></p>
      <calculation-input v-model="inputs.input1" class="mb-5" :disabled="!allowEditing" />

      <p class="mb-2">b) <stemble-latex :content="expression2" /></p>
      <calculation-input v-model="inputs.input2" class="mb-5" :disabled="!allowEditing" />

      <p class="mb-2">c) <stemble-latex :content="expression3" /></p>
      <calculation-input v-model="inputs.input3" class="mb-1" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'SigFigs7',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    expression1Integer() {
      return this.taskState.getValueBySymbol('expression1Integer').content;
    },
    expression2Integer() {
      return this.taskState.getValueBySymbol('expression2Integer').content;
    },
    expression3Integer() {
      return this.taskState.getValueBySymbol('expression3Integer').content;
    },
    expression1() {
      if (this.expression1Integer.value === 1) {
        return '$3.56+1.287\\text{E2}$';
      } else if (this.expression1Integer.value === 2) {
        return '$5.08+2.671\\text{E2}$';
      } else if (this.expression1Integer.value === 3) {
        return '$8.35+3.651\\text{E2}$';
      } else {
        return '$8.77+2.443\\text{E2}$';
      }
    },
    expression2() {
      if (this.expression2Integer.value === 1) {
        return '$1.659\\text{E1}-9.362$';
      } else if (this.expression2Integer.value === 2) {
        return '$2.644\\text{E1}-1.824$';
      } else if (this.expression2Integer.value === 3) {
        return '$3.491\\text{E1}-7.336$';
      } else {
        return '$3.007\\text{E1}-5.438$';
      }
    },
    expression3() {
      if (this.expression3Integer.value === 1) {
        return '$9.36-1.955\\text{E-1}$';
      } else if (this.expression3Integer.value === 2) {
        return '$7.21-1.878\\text{E-1}$';
      } else if (this.expression3Integer.value === 3) {
        return '$3.96-1.527\\text{E-1}$';
      } else {
        return '$2.41-2.847\\text{E-1}$';
      }
    },
  },
};
</script>
